<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

import "prismjs";
import "prismjs/themes/prism.css";
import animationData from "@/components/widgets/msoeawqm.json";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment/moment";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.scss";
import flatPickr from "vue-flatpickr-component";
import jwt_decode from "jwt-decode";
import notes from "@/components/management/bootcampNotes.vue";

export default {
  page: {
    title: "Basic",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Assistance List",
      items: [
        {
          text: "Bootcamp",
          href: "/",
        },
        {
          text: "Assistance List",
          active: true,
        },
      ],
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y"
      },
      dateTimeConfig: {
        enableTime: true,
        dateFormat: "F j, Y H:i K"
      },
      list: [],
      listQty: 0,
      agentData: {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        language: '',
        status: '',
        phone: '',
        department: '',
        state: '',
        birthDate: '',
        address: '',
        country: '',
        zipCode: '',
        interview: '',
        agentNumber: '',
        createdBy: '',
        insuranceLicense: '',
        licenseNumber: '',
        note: ''
      },
      departmentList: {},
      statusList: {},
      sourcesList: {},
      statesList: {},
      typeList: {},
      page: 1,
      perPage: 100,
      pages: [],
      searchQuery: null,
      humanfriendlyConfig: {
        dateFormat: "F j, Y",
      },
      data: {
        token: localStorage.getItem('jwt'),
        searchValues: {
          limitTo: this.perPage,
          source: '0',
          isActive: 1,
          noteSearch: '',
          isFinished: '0',
          keyword: '',
          licensed: 1,
          language: 3,
          status: 0,
          sortBy: 'name',
          orderBy: 'ASC',
          dateRange: 0,
          statesList: 'ALL',
          department: '0',
          toDate: new Date().toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) ,
          fromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}),
        }
      },
      defaultOptions: { animationData: animationData },
      notesData: [],
      idNumber: 1,
      password: '',
      confirmPasword: '',
      receiveNotifications:1,
      file: [],
      content: [],
      spectedColumnsindeed: [
        "nombre",
        "email",
        "teléfono",
        "estado",
        "ubicación del candidato",
        "puesto actual",
        "educación",
        "título del empleo",
        "ubicación del empleo",
        "fecha",
        "Nivel de interés",
        "Fuente",
      ],
      parsed: false,
      selectedDepartmentForAgentList: 0,
      isLoading: false,
      mobileView: true
    };
  },
  components: {
    notes,
    flatPickr,
    Layout,
    PageHeader,
    Multiselect
  },
  methods: {
    setPages() {

      let numberOfPages = Math.ceil(this.list.length / this.perPage);
      this.pages = []
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(companies) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return companies.slice(from, to);
    },
    async updateAssistance(userID=0, dayID=0, conditional=false){
      this.errors = []
      if (!this.errors.length) {
        const data = {
          token: localStorage.getItem('jwt'),
          userID: userID,
          dayID: dayID,
          conditional: conditional,
        }

        await axios
            .post('/api/updateAssistance', data)
            .then(async response => {
              if (response.status === 200) {
                await Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Assistance day '+ (parseInt(dayID)+1) +' updated!'
                });
              }
            })
            .catch(async error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }

                let list = '';
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                  list += '\n' + '<b>' + error.response.data[property] + '</b>';
                }

                await Swal.fire({
                  title: "Please check the following: " + list,
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      } else {
        let list = '';
        for (let i = 0; i < this.errors.length; i++)
          list += '\n' + '<b>' + this.errors[i] + '</b>';

        await Swal.fire({
          title: "Please complete the form! " + list,
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    formatDate: function(date, format){
      date = moment(date).format(format)

      if(date !== 'Invalid date'){
        return date;
      }else{
        return ' '
      }

    },
    async updateBootcampList(status='0') {

      let validate = true;
      let errors = [];
      let filterLanguage = document.getElementById("filterLanguage").value;
      let filterKeyword = document.getElementById("filterKeyword").value;
      let filterLicensed = document.getElementById("filterLicensed").value;
      let filterNotes = document.getElementById("filterNotes").value;
      let filterFromDate = document.getElementById("filterFromDate").value;
      let filterToDate = document.getElementById("filterToDate").value;

      if (status !== '0') {
        this.data.searchValues.status = status
      } else {
        this.data.searchValues.status = document.getElementById("filterStatus").value
      }

      if (filterLanguage !== '' && (filterLanguage === '1' || filterLanguage === '2')){
        this.data.searchValues.language = filterLanguage;
      }

      if (filterKeyword !== '' && filterKeyword.length >= 3) {
        this.data.searchValues.keyword = filterKeyword;
      } else if (filterKeyword !== '' && filterKeyword.length < 3) {
        errors.push('Keyword search should have 3 or more characters!')
        validate = false;
      }

      if(filterLicensed !== ''){
        this.data.searchValues.licensed = filterLicensed;
      }

      if (filterNotes !== '' && filterNotes.length >= 3) {
        this.data.searchValues.noteSearch = filterNotes;
      }else if(filterNotes !== '' && filterNotes.length < 3) {
        errors.push('Notes search should have 3 or more characters!')
        validate = false;
      }

      var start = moment(this.formatDate(filterFromDate, 'YYYY-MM-DD'));
      var end = moment(this.formatDate(filterToDate, 'YYYY-MM-DD'));
      var days = end.diff(start, "days");

      if(days <= 366){
        this.data.searchValues.fromDate = filterFromDate;
        this.data.searchValues.toDate = filterToDate;
      } else {
        errors.push("From Date and To Date (range) couldn't be larger than a year (365 days)!");
        validate = false;
      }

      const data = {
        token: localStorage.getItem('jwt'),
        isProspect: 0,
        searchValues: this.data.searchValues,
        userEmail: localStorage.getItem('email'),
        userID: localStorage.getItem('id'),
      }

      if(validate){
        await axios
            .post('/api/listBootcamp', data)
            .then(response => {

              this.list=response.data;
              this.listQty = this.list.length
              this.page = 1
              this.setPages();
            })
            .catch(error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }

                // for (const property in error.response.data) {
                // this.errors.push(`${property}: ${error.response.data[property]}`)
                // }
              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      }else{
        let list = '';
        for (var i = 0; i < errors.length; i+=1) {
          list += errors[i] + ' \n';
        }

        await Swal.fire("" + list, '', 'info');

      }

    },
    viewUserGroup(request) {
      const currentRole = jwt_decode(localStorage.getItem('jwt'))
      return currentRole.userGroup.includes(request)
    },
    async getNotes (id=localStorage.getItem('selectedBootcampUser'), userID=localStorage.getItem('selectedUser')) {
      const data = {
        token: localStorage.getItem('jwt'),
        userID: userID,
        agentID: id,
        clientID: id,
      }

      localStorage.setItem('selectedBootcampUser', id)
      localStorage.setItem('selectedUser', userID)

      let url = '';
      url = '/api/getBootcampNotes';

      await axios
          .post(url, data)
          .then(response => {
            this.notesData = response.data;
          })
          .catch(error => {
            if (error.response) {
              Swal.fire("Oops!", "Notes could not be loaded, please contact your SysAdmin.", "error")
            }
          })
    },
    updateBootcamp(ID, status) {

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger ml-2",
          cancelButton: "btn btn-success",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "Agent will be moved from the current list",
            icon: "warning",
            confirmButtonText: "Yes!",
            cancelButtonText: "No, cancel!",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {

              const data = {
                token: localStorage.getItem('jwt'),
                bootcampID: ID,
                bootcampStatus: status,
                userEmail: localStorage.getItem('email')
              }

              axios
                  .put('/api/bootcampUpdateStatus', data)
                  .then(async response => {
                    if (response.status === 200) {
                      await this.updateBootcampList()
                      await swalWithBootstrapButtons.fire(
                          "Moved!",
                          "Agent has been moved.",
                          "success"
                      );
                    }
                  })
                  .catch(async error => {
                    if (error.response) {
                      let list = '';
                      for (const property in error.response.data) {
                        this.errors.push(`${property}: ${error.response.data[property]}`)
                        list += '\n' + '<b>' + error.response.data[property] + '</b>';
                      }

                      await Swal.fire({
                        title: "Please check the following: " + list,
                        icon: 'warning',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false,
                        showCloseButton: true,
                      });

                    } else if (error.message) {
                      this.errors.push('Something was wrong, please contact your SysAdmin.')
                    }
                  })
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                  "Cancelled",
                  "Ok :)",
                  "error"
              );
            }
          });
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  async mounted() {

    let state = 'state';

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const selectedState = urlParams.get(state)

    if (selectedState != null) {
      this.data.searchValues.statesList = selectedState;
    }

    const data = {
      token: localStorage.getItem('jwt'),
      searchValues: this.data.searchValues,
      isActiveState: 1
    }

    await axios
        .post('/api/listDepartments', data)
        .then(response => {
          this.departmentList = response.data
        })
        .catch(error => {
          if (error.response) {

            if (error.response.data.detail === 'Your session is expired!') {
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Departments could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/listStatus', data)
        .then(response => {
          this.statusList = response.data
        })
        .catch(error => {
          if (error.response) {

            if (error.response.data.detail === 'Your session is expired!') {
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/listSource', data)
        .then(response => {
          this.sourcesList = response.data
        })
        .catch(error => {
          if (error.response) {

            if (error.response.data.detail === 'Your session is expired!') {
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/listStates', data)
        .then(response => {
          this.statesList = response.data
        })
        .catch(error => {
          if (error.response) {

            if (error.response.data.detail === 'Your session is expired!') {
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/listBootcamp', data)
        .then(response => {

          this.list=response.data;
          this.listQty = this.list.length
          this.page = 1
          this.setPages();
        })
        .catch(error => {
          if (error.response) {

            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }

            // for (const property in error.response.data) {
            // this.errors.push(`${property}: ${error.response.data[property]}`)
            // }
          } else if (error.message) {
            this.errors.push('Something was wrong, please contact your SysAdmin.')
          }
        })


    const checkbox = document.getElementsByClassName("code-switcher");
    Array.from(checkbox).forEach((check) => {
      check.addEventListener("change", () => {
        const card = check.closest(".card");
        const preview = card.querySelector(".live-preview");
        const code = card.querySelector(".code-view");
        if (check.checked) {
          // do this
          preview.classList.add("d-none");
          code.classList.remove("d-none");
        } else {
          // do that
          preview.classList.remove("d-none");
          code.classList.add("d-none");
        }
      });
    });
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.list);
    },
    resultQuery() {
      if (this.data.searchValues.keyword.length >= 3) {
        const search = this.data.searchValues.keyword.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
              data.address.toLowerCase().includes(search) ||
              data.agentNumber.toLowerCase().includes(search) ||
              data.licenseNumber.toLowerCase().includes(search) ||
              data.email.toLowerCase().includes(search) ||
              data.phoneNumber.toLowerCase().includes(search) ||
              data.firstName.toLowerCase().includes(search) ||
              data.lastName.toLowerCase().includes(search) ||
              data.fullname.toLowerCase().includes(search) ||
              data.statusName.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center flex-wrap gap-2">
              <div class="flex-grow-1">
                <div class="row">
                  <div class="col-xxl-4 col-sm-4" style="margin-bottom: 5px;">
                    <div class="search-box">
                      <input
                          type="text"
                          class="form-control search"
                          placeholder="Search for..."
                          title="Search by (Phone number, City, Postal code, Name, Last name, Full Name, Address, State)"
                          v-model="this.data.searchValues.keyword"
                          @change="this.data.searchValues.keyword.length >= 3 ? updateBootcampList(): ''"
                          v-on:keyup.enter="this.data.searchValues.keyword === '' ? updateBootcampList() : ''"
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>

                  <div class="col-xxl-4 col-sm-4" style="margin-bottom: 5px;">
                    <div class="d-flex align-items-center gap-2">
                      <span class="text-muted flex-shrink-0">Order by: </span>

                      <Multiselect class="form-control"
                                   style="padding: 0px;"
                                   v-model="this.data.searchValues.orderBy"
                                   autocomplete="off"
                                   @focus="handleFocus"
                                   :close-on-select="true"
                                   :searchable="true"
                                   :options="[
                            { value: 'ASC', label: 'Ascendance' },
                            { value: 'DESC', label: 'Descendant' },
                            ]"
                                   @select="updateBootcampList()"
                      />

                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0">
                <div class="hstack text-nowrap gap-2">
                  <button type="button" class="btn btn-soft-success float-end" href="#open-filters" data-bs-toggle="modal">
                    <i class="ri-filter-line align-bottom me-1"></i> Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <div class="live-preview">
              <div class="table-responsive">
                <table class="table align-middle table-nowrap mb-0">
                  <thead class="table-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Started Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Assistance Days</th>
                    <th scope="col">Notes / Is Finished?</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) of resultQuery" :key="index">
                    <td>
                      <div class="d-flex gap-2 align-items-center">
                        <div class="flex-grow-1">{{ data.firstName }} {{ data.lastName }}</div>
                      </div>
                    </td>
                    <td>{{ this.formatDate(data.createdOn, 'MMMM Do, YYYY') }}</td>
                    <td class="text-success">
                      {{ data.statusName }}
                    </td>
                    <td v-for="(n, i) of JSON.parse(data.assistanceJson)" :key="i">
                      <div class="form-check form-switch form-check-inline" dir="ltr">
                        <span  v-for="(x, y) of n.assistance" :key="y">
                          <label class="form-check-label" for="inlineswitch">&nbsp;{{ parseInt(y)+1 }}&nbsp;</label>
                          <input type="checkbox" class="form-check-input" :id="i" v-if="x" checked @click="updateAssistance(data.userID, y, false)">
                          <input type="checkbox" class="form-check-input" :id="i" v-else @click="updateAssistance(data.userID, y, true)">
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="hstack gap-2">
                        <div class="row">
                          <div class="col-md-6" style="margin-bottom: 5px;">
                            <button class="btn btn-warning btn-sm w-100"
                                    data-bs-toggle="modal" href="#open-notes" data-bs-trigger="hover"
                                    data-bs-placement="top" title="Notes" @click="getNotes(data.ID, data.userID)">
                              <i class="ri-sticky-note-line fs-16"></i>
                            </button>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check form-switch form-switch-lg form-check-inline form-switch-danger" dir="ltr">
                              <input type="checkbox" class="form-check-input" :id="data.id" v-if="data.isFinished === 1" checked @click="updateBootcamp(data.ID,0)">
                              <input type="checkbox" class="form-check-input" :id="data.id" v-else @click="updateBootcamp(data.ID,1)">
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!-- end table -->
              </div>
              <!-- end table responsive -->
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!--end row-->

    <!-- Filters Modal -->
    <div id="open-filters" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel">Filters</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <label for="filterKeyword" class="form-label">Keyword</label>
                <input type="text" id="filterKeyword" name="filterKeyword" class="form-control mb-2" placeholder="Enter Keyword" v-model="this.data.searchValues.keyword">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterLicensed" class="form-label">Licensed agents?</label>
                <select class="form-select mb-2" aria-label="Licensed Agents?" id="filterLicensed" name="filterLicensed" v-model="this.data.searchValues.licensed">
                  <option value="1" selected>Both</option>
                  <option value="2">Yes</option>
                  <option value="3">No</option>
                </select>
              </div>
              <div class="col-lg-6">
                <label for="editStatus" class="form-label">Status</label>
                <select class="form-select mb-2" aria-label="Status" id="filterStatus" v-model="this.data.searchValues.status">
                  <option value="0">All</option>
                  <option v-for="(status, statusIndex) of statusList" :key="statusIndex" v-bind:value="status.statusID">{{ status.name }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="editLanguage" class="form-label">Language</label>
                <select class="form-select mb-2" aria-label="Language" id="filterLanguage" name="filterLanguage" v-model="this.data.searchValues.language">
                  <option value="3" selected>Both</option>
                  <option value="1">English</option>
                  <option value="2">Spanish</option>
                </select>
              </div>
              <div class="col-lg-6">
                <label for="editStatus" class="form-label">Search by Notes</label>
                <input type="text" id="filterNotes" name="filterNotes" class="form-control mb-2" placeholder="Enter note" v-model="this.data.searchValues.noteSearch">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterFromDate" class="form-label"> From date </label>
                <flat-pickr
                    v-model="this.data.searchValues.fromDate"
                    :config="humanfriendlyConfig"
                    class="form-control flatpickr-input"
                    id="filterFromDate"
                    name="filterFromDate"
                ></flat-pickr>
              </div>
              <div class="col-lg-6">
                <label for="filterToDate" class="form-label"> To Date </label>
                <flat-pickr
                    v-model="this.data.searchValues.toDate"
                    :config="humanfriendlyConfig"
                    class="form-control flatpickr-input"
                    id="filterToDate"
                    name="filterToDate"
                ></flat-pickr>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterType" class="form-label">Source</label>
                <select class="form-select mb-2" aria-label="Status" id="filterType" name="filterType" v-model="this.data.searchValues.source">
                  <option value="0">All</option>
                  <option v-for="(source, typeIndex) of sourcesList" :key="typeIndex" v-bind:value="source.sourceID">{{ source.name }}</option>
                </select>
              </div>
              <div class="col-lg-6">
                <label for="filterState" class="form-label">State</label>
                <select class="form-select mb-2" aria-label="Source" id="filterState" v-model="this.data.searchValues.statesList" required>
                  <option v-for="(source, sourceIndex) of statesList" :key="sourceIndex" v-bind:value="source.value">{{ source.label }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterDateRange" class="form-label">Time Range</label>
                <select class="form-select mb-2" id="filterDateRange" name="filterDateRange" v-model="this.data.searchValues.dateRange">
                  <option value="0" selected>...</option>
                  <option value="1">Until 2 year Ago</option>
                  <option value="2">Until 4 year Ago</option>
                  <option value="3">Until 5 year Ago</option>
                  <option value="4">Begin of times</option>
                </select>
              </div>
              <div class="col-lg-6">
                <span v-if="viewUserGroup('SUPER_ADMIN_GROUP')">
                  <label for="filterDateRange" class="form-label">Department</label>
                    <select class="form-select mb-2" aria-label="Department" id="filterDepartment" name="filterDepartment"
                            v-model="this.data.searchValues.department">
                        <option value="0"> All</option>
                        <option v-for="(department, index) of departmentList"  :key="index"
                                v-bind:value="department.departmentID"> {{ department.name }}
                        </option>
                    </select>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterFinished" class="form-label">Is Finished?</label>
                  <select class="form-select mb-2" aria-label="Finished" id="filterFinished" name="filterFinished"
                          v-model="this.data.searchValues.isFinished">
                      <option value=""> All</option>
                      <option value="1"> Yes</option>
                      <option value="0"> No</option>
                  </select>
              </div>
              <div class="col-lg-6">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="updateBootcampList()">Search</button>
          </div>

        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <!-- Notes Modal -->
    <div id="open-notes" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <notes :dataList="notesData" @onFire="getNotes()" :idNumber="2"> </notes>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
  </Layout>
</template>

<script>

import moment from "moment";
import {
  SearchIcon,
  InfoIcon,
} from "@zhuowenli/vue-feather-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { ref } from "vue";
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/dist/style.css'

export default {
  name: 'notes',
  props:['dataList', 'agentID', 'idNumber'],
  data () {
    return {
      profile: require("@/assets/images/users/user-dummy-img.jpg"),
      submitted: false,
      userName: '',
      text: ref(""),
      isOpen: ref(false),
      isOpenFile: ref(false),
      uploadFiles: '',
      crmSelectedUser: 0,
      crmUserID: localStorage.getItem('selectedUser'),
      previewImages: ref([])
    };
  },
  components: {
    SearchIcon,
    InfoIcon,
    EmojiPicker
  },
  methods: {
    formatDate: function (date, format) {
      date = moment(date).format(format)
      return date;
    },
    async saveNote () {

      const formData = new FormData();
      formData.append('note', document.getElementById('userNote'+this.idNumber).value);
      formData.append('bootcampUser', localStorage.getItem('selectedBootcampUser'));
      formData.append('agentID', localStorage.getItem('selectedUser'));
      formData.append('userID', localStorage.getItem('selectedUser'));
      formData.append('userEmail', localStorage.getItem('email'));
      formData.append('token', localStorage.getItem('jwt'));

      await axios
          .post('/api/saveBootcampNote',formData)
          .then(async response => {

            if (response.status === 200) {
              document.getElementById('userNote'+this.idNumber).value = ''
              this.text= ref("");
              this.previewImages= ref("");
              this.isOpenFile= ref(false);
              this.$emit('onFire', response.data)
            }

          }).catch(async error => {
            if (error.response) {
              let list = '';
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
                list += '\n' + '<b>' + error.response.data[property] + '</b>';
              }

              await Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Please check the following: " + list,
                showConfirmButton: false,
                timer: 5000,
              });

            } else if (error.message) {
              this.errors.push('Something was wrong, please contact your SysAdmin.')
            }
          })
    },
    onSelectEmoji(emoji) {

      const input =  this.$refs.inputRef

      const currentCursorPosition = input.selectionStart;
      const currentValue = input.value;

      const newValue =
          currentValue.slice(0, currentCursorPosition) +
          emoji.i +
          currentValue.slice(currentCursorPosition);

      this.text = newValue;

      input.focus();

      setTimeout(() => {
        const newCursorPosition = currentCursorPosition + emoji.i.length;
        input.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    },
    // handleFileUpload( event ){
    //   this.uploadFiles = event.target.files[0];
    //   console.log(this.uploadFiles)
    // },

    handleFileChange (event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Validate file type (optional)
        if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
          alert('Only images (JPEG, PNG, GIF) are allowed!');
          continue;
        }

        // Check for file size limit (optional)
        if (file.size > 1024 * 1024 * 5) { // 5 MB limit
          alert('File size limit exceeded (5 MB maximum)!');
          continue;
        }

        const reader = new FileReader();
        // reader.onload = (e) => {
        //   previewImages.value.push(e.target.result);
        // };

        reader.onload = (e) => {
          this.previewImages.push({
            preview: e.target.result,
            name: file.name,
            size: file.size,
          });
        };
        // this.uploadFiles = previewImages
        reader.readAsDataURL(file);
      }
    },

    removeImage (index) {
      this.previewImages.splice(index, 1);
    },

    formatBytes (bytes) {
      if (bytes === 0) return '0 B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat(bytes / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i];
    }

  },
}

</script>
<template>
  <div class="chat-wrapper">
    <!-- Start User chat -->
    <div class="w-100 overflow-hidden">
      <div class="chat-content d-lg-flex">
        <!-- start chat conversation section -->
        <div class="w-100 overflow-hidden position-relative">
          <!-- conversation user -->
          <div class="position-relative">
            <div class="p-3 user-chat-topbar" style="position: inherit;">
              <div class="row align-items-center">
                <div class="col-sm-4 col-8">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <div class="d-flex align-items-center">
                        <div
                            class="
                                            flex-shrink-0
                                            chat-user-img
                                            online
                                            user-own-img
                                            align-self-center
                                            me-3
                                            ms-0">
                          <img :src="profile ? profile :
                                          require('@/assets/images/users/user-dummy-img.jpg')"
                               class="rounded-circle avatar-xs"
                               alt=""/>
                          <span class="user-status"></span>
                        </div>
                        <div class="flex-grow-1 overflow-hidden">
                          <h5 class="text-truncate mb-0 fs-16">
                            <a
                                class="text-reset username"
                                data-bs-toggle="offcanvas"
                                href="#userProfileCanvasExample"
                                aria-controls="userProfileCanvasExample"
                            >{{ this.dataList[1] }}</a>
                          </h5>
                          <p class="
                                text-truncate text-muted
                                fs-14
                                mb-0
                                userStatus">
                            <small>Notes</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8 col-4">
                  <ul class="list-inline user-chat-nav text-end mb-0">
                    <li class="list-inline-item m-0">
                      <div class="dropdown">
                        <button
                            class="btn btn-ghost-secondary btn-icon"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <search-icon class="icon-sm"></search-icon>
                        </button>
                        <div
                            class="
                            dropdown-menu
                            p-0
                            dropdown-menu-end dropdown-menu-lg
                          "
                        >
                          <div class="p-2">
                            <div class="search-box">
                              <input
                                  type="text"
                                  class="form-control bg-light border-light"
                                  placeholder="Search here..."
                                  onkeyup="searchMessages()"
                                  id="searchMessage"
                              />
                              <i class="ri-search-2-line search-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="list-inline-item d-none d-lg-inline-block m-0">
                      <button
                          type="button"
                          class="btn btn-ghost-secondary btn-icon"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#userProfileCanvasExample"
                          aria-controls="userProfileCanvasExample"
                      >
                        <info-icon class="icon-sm"></info-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end chat user head -->
            <div class="position-relative" id="users-chat">
              <div
                  class="chat-conversation p-3 p-lg-4"
                  id="chat-conversation"
                  data-simplebar
                  ref="current">
                <ul class="list-unstyled chat-conversation-list" id="list-unstyled-notes">
                  <li v-for="messageData in dataList[0]"
                      :key="messageData.noteID" class="chat-list right">
                    <div class="conversation-list">
                      <div class="chat-avatar" v-if="messageData.align !== 'right'">
                        <img :src=" messageData.profileIMG ? messageData.profileIMG : require('@/assets/images/users/user-dummy-img.jpg')"
                             alt="" :title="messageData.createdBy"/>
                      </div>
                      <div class="user-chat-content">
                        <div class="ctext-wrap">
                          <div class="ctext-wrap-content">
                            <p class="mb-0 ctext-content">
                              {{ messageData.note }}
                            </p>
                            <div>
                              <small class="text-muted" style="font-size: 0.7em;">{{ messageData.createdBy }}</small>
                            </div>
                            <div v-if="messageData.files.length > 0">
                              <div class="d-flex align-items-start">
                              <span v-for="files in messageData.files" :key="files.ID" class="align-items-center">
                              <a class="thumbnail" :download="files.fileName" :href="files.url" :title="files.fileName" >
                                <img :src="files.url" width="100px" height="66px" class="img-fluid rounded img-thumbnail">
                                <span>
                                  <img :src="files.url"
                                       :alt="files.fileName" :title="files.fileName" />
                                  <br/>
                                </span>
                              </a>
                                <!--                                <a :download="files.fileName" :href="files.url" :title="files.fileName">-->
                                <!--                                    <b-img class="img-fluid rounded img-thumbnail"-->
                                <!--                                           :src="files.url"-->
                                <!--                                           :alt="files.fileName" :title="files.fileName" style="height: 70px; width: 70px;"></b-img>-->
                                <!--                                </a>-->
                              </span>
                              </div>
                            </div>
                          </div>
                          <div class="dropdown align-self-start message-box-drop">
                            <a
                                class="dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                              <i class="ri-more-2-fill"></i>
                            </a>
                            <div class="dropdown-menu">
                              <a class="dropdown-item reply-message" href="#">
                                <i class="
                                                    ri-reply-line
                                                    me-2
                                                    text-muted
                                                    align-bottom"></i>Reply</a>
                              <a class="dropdown-item" href="#">
                                <i class="
                                                  ri-share-line
                                                  me-2
                                                  text-muted
                                                  align-bottom"></i>Forward</a>
                              <a class="dropdown-item copy-message" href="#">
                                <i class="
                                                  ri-file-copy-line
                                                  me-2
                                                  text-muted
                                                  align-bottom"></i>Copy</a>
                              <a class="dropdown-item" href="#">
                                <i class="
                                                ri-bookmark-line
                                                me-2
                                                text-muted
                                                align-bottom"></i >Bookmark</a
                              >
                              <a class="dropdown-item delete-item" href="#">
                                <i class="
                                                    ri-delete-bin-5-line
                                                    me-2
                                                    text-muted
                                                    align-bottom"></i>Delete</a>
                            </div>
                          </div>
                          <div class="conversation-name">
                            <div>
                              <small class="text-muted time">{{
                                  this.formatDate(messageData.createdOn, 'MM/D/YYYY H:mm')
                                }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- end chat-conversation-list -->
              </div>
            </div>

            <!-- end chat-conversation -->
            <div class="chat-input-section p-3 p-lg-4">
              <form class="mt-2" @submit.prevent="formSubmit">
                <div>
                <textarea class="form-control border-bottom-0 rounded-top rounded-0 border"
                          rows="3"
                          placeholder="Please Enter a Note"
                          ref="inputRef"
                          :id="'userNote'+this.idNumber"
                          v-model="this.text"
                          :class="{'is-invalid': submitted && v$.form.message.$error,}"
                ></textarea>
                  <EmojiPicker v-if="isOpen" @select="onSelectEmoji" />
                  <div v-if="isOpenFile">

                    <div style="margin-top: 10px;">
                      <h3>Notes File Upload</h3>
                      <div style="margin-bottom: 10px">
                        <input class="form-control" type="file" ref="fileInput"  @change="handleFileChange" multiple id="fileInput">
                      </div>
                      <ul v-if="previewImages.length">
                        <li v-for="(image, index) in previewImages" :key="index" style="list-style: none;">
                          <div class="row">
                            <div class="col-md-4">
                              <img :src="image.preview" alt="Preview" style="width: 60%; margin-top: 10px;" class="img-thumbnail">
                            </div>
                            <div class="col-md-4 align-content-center ">
                              <span>{{ image.name }} <br> ({{ formatBytes(image.size) }})</span>
                            </div>
                            <div class="col-md-4 d-flex justify-content-end align-items-center">
                              <BButton variant="danger" class="btn-icon waves-effect waves-light" @click="removeImage(index)"><i class="ri-delete-bin-5-line"></i></BButton>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                      v-if="submitted && v$.form.message.$error"
                      class="invalid-feedback">
                                    <span v-if="v$.form.message.required.$message">{{
                                        v$.form.message.required.$message
                                      }}</span>
                  </div>
                  <div class="bg-light px-2 py-1 rouned-bottom border">
                    <div class="row">
                      <div class="col">
                        <div class="btn-group" role="group">
                          <button
                              @click="isOpen = !isOpen"
                              type="button"
                              class="btn btn-link text-decoration-none emoji-btn"
                              id="emoji-btn">
                            <i class="bx bx-smile"></i>
                          </button>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="btn-group">
                          <button class="btn btn-sm btn-success" @click="saveNote" id="saveNote" type="submit">
                            <i class="ri-send-plane-2-fill align-bottom"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="replyCard">
              <div class="card mb-0">
                <div class="card-body py-3">
                  <div
                      class="replymessage-block mb-0 d-flex align-items-start"
                  >
                    <div class="flex-grow-1">
                      <h5 class="conversation-name"></h5>
                      <p class="mb-0"></p>
                    </div>
                    <div class="flex-shrink-0">
                      <button
                          type="button"
                          id="close_toggle"
                          class="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                      >
                        <i class="bx bx-x align-middle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.thumbnail {
  position: relative;
  z-index: 1000;
}

.thumbnail:hover {
  background-color: transparent;
  z-index: 0;
}

.thumbnail span {
  /*CSS for enlarged image*/
  position: fixed;
  background-color: transparent;
  padding: 5px;
  //left: -1000px;
  //border: 1px dashed gray;
  visibility: hidden;
  color: black;
  text-decoration: none;
  width: 500px;
  height: 200px;
  //top: 50%;
  //left: 50%;
  margin-top: 500px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
}

.thumbnail span img {
  /*CSS for enlarged image*/
  border-width: 0;
  padding: 2px;
}

.thumbnail:hover span {
  /*CSS for enlarged image on hover*/
  visibility: visible;
  top: 0;
  left: 60px; /*position where enlarged image should offset horizontally */
}
</style>